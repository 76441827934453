@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";
@import 'ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "bootstrap/scss/bootstrap";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}

@media print {
  body {
    visibility: hidden;
  }
  #printable * {
    visibility: visible;
    width: 100%;
  }
}

.list-group-item {
  border: 0 !important;
  padding: 0 !important;
}

.modal-footer {
  justify-content: center !important;
}